<template>
  <header class="navigation-top">
  </header>
</template>

<script>
// import notificationComponent from "../notification";
// import settingsNav from "../settings-nav";

export default {
  data() {
    return {
      pre: "",
      homepre: "",
      isShow: false
    };
  },
  components: {
    // notification: notificationComponent,
    // settingsNav: settingsNav
  },
  computed: {
    getUserData() {
      return this.$store.state.auth;
    }
  },
  methods: {
    logOutSection() {
      this.$store.dispatch("logOutSection");
    }
  }
};
</script>

<style lang="scss" scoped>
.Mobile-navigation {
  background: #fff;
  box-shadow: 0 0 10px #00000029;
  border-radius: 10px;
  padding: 10px 15px;
  ul {
    li {
      display: block;
      width: 100%;
      border-bottom: 1px solid #ebebeb;
      a {
        font-size: 13px;
      }
    }
  }
}
.mobile-btn-menu.text-center {
  width: 100%;
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 575px) {
  .mobile-btn-menu.text-center {
    padding: 10px 0;
    width: 100%;
    button {
      font-size: 16px;
    }
  }
  body header .top-right .dropdown button {
    width: 30px;
    height: 30px;
  }
  body header .top-right .dropdown .dropdown-menu.prof-len {
    width: 270px;
  }
  body header .top-right .dropdown .dropdown-menu.not-len {
    width: 240px;
    padding: 15px 10px 10px;
  }
  body
    header
    .top-right
    .dropdown
    .dropdown-menu
    .not-groups
    .notifications-listing
    .list-group-flush
    li.list-group-item
    .media
    h6 {
    line-height: 21px;
    font-size: 14px;
  }
  body
    header
    .top-right
    .dropdown
    .dropdown-menu
    .not-groups
    .notifications-listing
    .list-group-flush
    li.list-group-item
    .media
    p {
    font-size: 13px;
  }
  body
    header
    .top-right
    .dropdown
    .dropdown-menu
    .not-groups
    .notifications-listing
    .list-group-flush
    li.list-group-item
    .media
    .vue-avatar--wrapper.mr-3 {
    width: 35px !important;
    height: 35px !important;
    font-size: 12px !important;
    margin-right: 10px !important;
  }
  body {
    header {
      .logo {
        img {
          width: 76px;
        }
      }
    }
  }
}

@media (min-width: 576px) {
  .mobile-btn-menu.text-center {
    padding: 14px 0;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .mobile-btn-menu.text-center {
    padding: 14px 0;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
</style>
