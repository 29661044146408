var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"navigation-top"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('nav',{staticClass:"navbar",class:{navbaropen: _vm.opened},attrs:{"id":"navigator"}},[_c('span',{staticClass:"open-slide"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){_vm.opened = !_vm.opened}}},[_c('div',[_c('div',{staticClass:"bar",class:{topopen: _vm.opened}}),_c('div',{staticClass:"bar",class:{midopen: _vm.opened}}),_c('div',{staticClass:"bar",class:{botopen: _vm.opened}})])])]),_vm._m(0),(_vm.getBreadCums.breadcums)?_c('div',{staticClass:"bread-come"},[((Object.keys(_vm.getBreadCums.breadcums.parent).length))?[_c('span',{staticClass:"bread-come"},[_c('router-link',{attrs:{"to":{
                  path:  _vm.getBreadCums.breadcums.parent.path
                },"tag":"a"}},[_vm._v(" "+_vm._s(_vm.getBreadCums.breadcums.parent.title)+" ")])],1)]:_vm._e(),_c('span',{staticClass:"bread-come",class:_vm.getBreadCums.breadcums.disabled ? 'normal-title' : ''},[_vm._v(" "+_vm._s(_vm.getBreadCums.breadcums.title)+" ")])],2):_c('div',{staticClass:"bread-come"},[_c('span',{staticClass:"bread-come"},[_vm._v("Home")])])]),_c('div',{staticClass:"side-nav",class:{sidenavopen: _vm.opened},attrs:{"id":"side-menu"}},[_c('router-link',{attrs:{"to":{
            path: '/home'
          },"tag":"a"}},[_vm._v(" Home ")]),_c('router-link',{attrs:{"to":{
            path: '/customers'
          },"tag":"a"}},[_vm._v(" Customers ")]),_c('router-link',{attrs:{"to":{
            path: '/countries-list'
          },"tag":"a"}},[_vm._v(" Counties List ")]),_c('router-link',{attrs:{"to":{
            path: '/all-documents'
          },"tag":"a"}},[_vm._v(" All Documents ")]),_c('router-link',{attrs:{"to":{
            path: '/rejected-reasons'
          },"tag":"a"}},[_vm._v(" Rejected Reasons ")]),_c('router-link',{attrs:{"to":{
            path: '/verification-documents-types'
          },"tag":"a"}},[_vm._v(" Additional Documents Templates ")]),_c('router-link',{attrs:{"to":{
            path: '/verification-documents-list-items'
          },"tag":"a"}},[_vm._v(" Additional Documents Templates List Items ")]),_c('router-link',{attrs:{"to":{
            path: '/ticket'
          },"tag":"a"}},[_vm._v(" Tickets ")]),_c('router-link',{attrs:{"to":{
            path: '/document-upload'
          },"tag":"a"}},[_vm._v(" Upload Documents ")]),_c('router-link',{attrs:{"to":{
            path: '/additional-documents'
          },"tag":"a"}},[_vm._v(" Additional Documents ")]),_c('router-link',{attrs:{"to":{
            path: '/custom-categories'
          },"tag":"a"}},[_vm._v(" Add Custom Categories ")]),_c('router-link',{attrs:{"to":{
            path: '/custom-title-for-additional-documents'
          },"tag":"a"}},[_vm._v(" Add Additional Document Title ")]),_c('router-link',{attrs:{"to":{
            path: '/custom-description-for-additional-documents'
          },"tag":"a"}},[_vm._v(" Add Additional Document Description ")]),_c('router-link',{attrs:{"to":{
            path: '/emails/templates'
          },"tag":"a"}},[_vm._v(" Email Templates ")]),_c('router-link',{attrs:{"to":{
            path: '/emails/auto'
          },"tag":"a"}},[_vm._v(" Email List ")]),_c('router-link',{attrs:{"to":{
            path: '/faq'
          },"tag":"a"}},[_vm._v(" Faq ")]),_c('router-link',{attrs:{"to":{
            path: '/faq-categories'
          },"tag":"a"}},[_vm._v(" Faq Categories ")]),_c('router-link',{attrs:{"to":{
            path: '/transactions'
          },"tag":"a"}},[_vm._v(" Transactions ")]),_c('router-link',{attrs:{"to":{
            path: '/login-logs'
          },"tag":"a"}},[_vm._v(" Registered Devices ")]),_c('router-link',{attrs:{"to":{
            path: '/reports/transactions'
          },"tag":"a"}},[_vm._v(" Reports - Users with Zero transaction ")]),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.logOutSection.apply(null, arguments)}}},[_vm._v(" Logout ")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"logo"},[_c('img',{attrs:{"src":"https://s3.eu-west-2.amazonaws.com/assets.xonder.co.uk/logo/logo.svg"}})])
}]

export { render, staticRenderFns }