/* eslint-disable */
<template>
  <div id="app">
    <div v-if="getGlobalLoader" class="main-wrapper main-loader-home">
      <div class="lds-sub-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
    <div
      v-if="
        typeof getUserData !== 'undefined' &&
          Object.keys(getUserData).length
      "
    >
      <div class="container-fluid zero-padding " v-if="getUserData.loggedIn">
          <transition name="fade" mode="out-in">
            <main-header></main-header>
          </transition>
          <transition name="fade" mode="out-in">
            <div id="main" >
              <vue-progress-bar></vue-progress-bar>
              <router-view></router-view>
            </div>
          </transition>
          <transition name="fade" mode="out-in">
            <main-footer></main-footer>
          </transition>
      </div>
      <div v-else>
        <router-view />
      </div>
    </div>
    <div v-else class="main-wraper-loader">
      <div class="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>
<script>
import admin_header from "./components/header/index";
import admin_footer from "./components/footer/index";

export default {
  data() {
    return {};
  },
  created () {
    //  [App.vue specific] When App.vue is first loaded start the progress bar
    this.$Progress.start()
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress
        // parse meta tags
        this.$Progress.parseMeta(meta)
      }
      //  start the progress bar
      this.$Progress.start()
      //  continue to next page
      next()
    })
    //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach((to, from) => {
      //  finish the progress bar
      this.$Progress.finish()
    })
  },
  components: {
    "main-header": admin_header,
    "main-footer": admin_footer
  },
  computed: {
    getUserData() {
      return this.$store.state.auth;
    },
    getGlobalLoader() {
      return this.$store.state.general_load;
    }
  }
};
</script>
<style lang="scss" scoped>
.main-loader-home {
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
    top: 0;
    cursor: wait;
    background: rgba(0,0,0,0.5);
}
</style>
